import React from 'react';
import type { TabsAlignment } from 'wix-ui-tpa/cssVars';
import { Tabs, TabsSkin, Dropdown, DropdownTheme } from 'wix-ui-tpa/cssVars';
import { useNavigationLogic } from './Navigation.logic';
import type { PopulatedMenu } from 'root/apiTypes';
import type { NavigateToFn } from 'root/clientTypes';
import { MenuListNavigation, NavigationDropdownStyle } from 'root/consts';
import { classes } from './Navigation.st.css';
import { dataHooks } from 'root/dataHooks';
import type { ILocation } from '@wix/yoshi-flow-editor';

export const Navigation = ({
  activeItem,
  setActiveItem,
  menus,
  navigateTo,
  currentUrl,
}: {
  activeItem: number;
  setActiveItem: (index: number) => void;
  menus: PopulatedMenu[];
  navigateTo: NavigateToFn;
  currentUrl: ILocation['url'];
}) => {
  const {
    navigationLayout,
    tabsWidth,
    dropdownOptions,
    navigationAlignmentString,
    tabOptions,
    onClickNavigationDropdownOption,
    onClickTab,
    navigationDropdownStyle,
    renderButHideTabsForSeo,
  } = useNavigationLogic({
    menus,
    setActiveItem,
    navigateTo,
    currentUrl,
  });

  return (
    <div className={classes.container} data-hook={dataHooks.navigation.container}>
      <Tabs
        activeTabIndex={activeItem}
        alignment={navigationAlignmentString as TabsAlignment}
        className={`${classes.tabs} ${renderButHideTabsForSeo && classes.hidden}`}
        data-hook={dataHooks.navigation.tabs}
        items={tabOptions}
        skin={TabsSkin.fitUnderline}
        variant={tabsWidth}
        onTabClick={onClickTab}
      />
      {navigationLayout === MenuListNavigation.Dropdown && (
        <div style={{ display: 'flex', justifyContent: navigationAlignmentString }}>
          <div style={{ width: '300px' }}>
            <Dropdown
              className={classes.dropdown}
              data-hook={dataHooks.navigation.dropdown}
              initialSelectedId={activeItem.toString()}
              options={dropdownOptions}
              theme={navigationDropdownStyle === NavigationDropdownStyle.Box ? DropdownTheme.Box : DropdownTheme.Line}
              onChange={onClickNavigationDropdownOption}
            />
          </div>
        </div>
      )}
    </div>
  );
};
