import { Alignment } from 'root/consts';

export const getAlignmentText = (alignment: Alignment): string => {
  switch (alignment) {
    case Alignment.Center:
      return 'center';
    case Alignment.End:
      return 'end';
    case Alignment.Start:
    default:
      return 'start';
  }
};
