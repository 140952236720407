import { StyleParamType, createStylesParams } from '@wix/tpa-settings';
import type { ExtractStyleParamValue, StylesParamsType } from 'root/components/Menus/stylesParams';
import { Alignment, ImageRatio, ImageSizeCategories, MenuListNavigation } from 'root/consts';
import { Preset } from 'root/utils/presets';
import { DEFAULT_SMALL_SPACING, MIN_SPACING_RANGE } from 'root/components/Menus/Settings/Fields/consts';

export type IGridLayoutStylesParams = {
  gridNumOfColumns: StyleParamType.Number;
  gridMenuHorizontalPadding: StyleParamType.Number;
  gridMenuVerticalPadding: StyleParamType.Number;
  gridMenuAlignment: StyleParamType.Number;
  gridSectionsGap: StyleParamType.Number;
  gridSectionPadding: StyleParamType.Number;
  gridSectionAlignment: StyleParamType.Number;
  gridItemsRowGap: StyleParamType.Number;
  gridItemsColumnGap: StyleParamType.Number;
  gridItemPadding: StyleParamType.Number;
  gridItemAlignment: StyleParamType.Number;
  gridItemDetailsPadding: StyleParamType.Number;
  gridMenuNavigationLayout: StyleParamType.Number;
  gridMenuNavigationFullWidthTab: StyleParamType.Boolean;
  gridMenuNavigationAlignment: StyleParamType.Number;
  gridItemImageBottomPadding: StyleParamType.Number;
  gridItemImageRatio: StyleParamType.Number;
};

export const gridLayoutStylesParams = createStylesParams<IGridLayoutStylesParams>({
  gridNumOfColumns: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 1 : 3),
  },
  gridMenuHorizontalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => DEFAULT_SMALL_SPACING,
  },
  gridMenuVerticalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => DEFAULT_SMALL_SPACING,
  },
  gridMenuAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: () => Alignment.Start,
  },
  gridSectionsGap: {
    type: StyleParamType.Number,
    getDefaultValue: () => 80,
  },
  gridSectionPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  gridSectionAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: () => Alignment.Start,
  },
  gridItemsRowGap: {
    type: StyleParamType.Number,
    getDefaultValue: () => 40,
  },
  gridItemsColumnGap: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  gridItemPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  gridItemAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: () => Alignment.Start,
  },
  gridItemDetailsPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  gridMenuNavigationLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => MenuListNavigation.Tabs,
  },
  gridMenuNavigationFullWidthTab: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  gridMenuNavigationAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: () => Alignment.Start,
  },
  gridItemImageBottomPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  gridItemImageRatio: {
    type: StyleParamType.Number,
    getDefaultValue: () => ImageRatio['1:1'],
  },
});

export type IColumnsLayoutStylesParams = {
  columnsNumOfColumns: StyleParamType.Number;
  columnsMenuHorizontalPadding: StyleParamType.Number;
  columnsMenuVerticalPadding: StyleParamType.Number;
  columnsSectionsGap: StyleParamType.Number;
  columnsSectionPadding: StyleParamType.Number;
  columnsItemsGap: StyleParamType.Number;
  columnsColumnsGap: StyleParamType.Number;
  columnsItemPadding: StyleParamType.Number;
  columnsMenuAlignment: StyleParamType.Number;
  columnsSectionAlignment: StyleParamType.Number;
  columnsItemImageRatio: StyleParamType.Number;
  columnsItemImageSize: StyleParamType.Number;
  columnsMenuNavigationLayout: StyleParamType.Number;
  columnsMenuNavigationFullWidthTab: StyleParamType.Boolean;
  columnsMenuNavigationAlignment: StyleParamType.Number;
};

export const sideBySideLayoutStylesParams = createStylesParams<ISideBySideLayoutStylesParams>({
  sideBySideMenuHorizontalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? DEFAULT_SMALL_SPACING : MIN_SPACING_RANGE),
  },
  sideBySideMenuVerticalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? DEFAULT_SMALL_SPACING : MIN_SPACING_RANGE),
  },
  sideBySideSectionsGap: {
    type: StyleParamType.Number,
    getDefaultValue: () => 40,
  },
  sideBySideSectionPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sideBySideItemsGap: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  sideBySideItemPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sideBySideMenuAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: () => Alignment.Start,
  },
  sideBySideSectionAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: () => Alignment.Start,
  },
  sideBySideMenuNavigationLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => MenuListNavigation.Tabs,
  },
  sideBySideMenuNavigationFullWidthTab: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  sideBySideMenuNavigationAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: () => Alignment.Start,
  },
});

export type ISideBySideLayoutStylesParams = {
  sideBySideMenuHorizontalPadding: StyleParamType.Number;
  sideBySideMenuVerticalPadding: StyleParamType.Number;
  sideBySideSectionsGap: StyleParamType.Number;
  sideBySideSectionPadding: StyleParamType.Number;
  sideBySideItemsGap: StyleParamType.Number;
  sideBySideItemPadding: StyleParamType.Number;
  sideBySideMenuAlignment: StyleParamType.Number;
  sideBySideSectionAlignment: StyleParamType.Number;
  sideBySideMenuNavigationLayout: StyleParamType.Number;
  sideBySideMenuNavigationFullWidthTab: StyleParamType.Boolean;
  sideBySideMenuNavigationAlignment: StyleParamType.Number;
};

export const columnsLayoutStylesParams = createStylesParams<IColumnsLayoutStylesParams>({
  columnsNumOfColumns: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 1 : 2),
  },
  columnsMenuHorizontalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => DEFAULT_SMALL_SPACING,
  },
  columnsMenuVerticalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => DEFAULT_SMALL_SPACING,
  },
  columnsSectionsGap: {
    type: StyleParamType.Number,
    getDefaultValue: () => 40,
  },
  columnsSectionPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  columnsItemsGap: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  columnsColumnsGap: {
    type: StyleParamType.Number,
    getDefaultValue: () => 60,
  },
  columnsItemPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  columnsMenuAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: () => Alignment.Start,
  },
  columnsSectionAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: () => Alignment.Start,
  },
  columnsItemImageRatio: {
    type: StyleParamType.Number,
    getDefaultValue: () => ImageRatio['1:1'],
  },
  columnsItemImageSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => ImageSizeCategories.MEDIUM,
  },
  columnsMenuNavigationLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => MenuListNavigation.Tabs,
  },
  columnsMenuNavigationFullWidthTab: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  columnsMenuNavigationAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: () => Alignment.Start,
  },
});

export type ICenterLayoutStylesParams = {
  centerMenuHorizontalPadding: StyleParamType.Number;
  centerMenuVerticalPadding: StyleParamType.Number;
  centerSectionsGap: StyleParamType.Number;
  centerSectionPadding: StyleParamType.Number;
  centerSectionDividerMargin: StyleParamType.Number;
  centerItemsRowGap: StyleParamType.Number;
  centerItemPadding: StyleParamType.Number;
  centerItemDetailsPadding: StyleParamType.Number;
  centerMenuNavigationLayout: StyleParamType.Number;
  centerMenuNavigationFullWidthTab: StyleParamType.Boolean;
};

export const centerLayoutStylesParams = createStylesParams<ICenterLayoutStylesParams>({
  centerMenuHorizontalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => DEFAULT_SMALL_SPACING,
  },
  centerMenuVerticalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => DEFAULT_SMALL_SPACING,
  },
  centerSectionsGap: {
    type: StyleParamType.Number,
    getDefaultValue: () => 60,
  },
  centerSectionPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  centerSectionDividerMargin: {
    type: StyleParamType.Number,
    getDefaultValue: () => 40,
  },
  centerItemsRowGap: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  centerItemPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  centerItemDetailsPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  centerMenuNavigationLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => MenuListNavigation.Tabs,
  },
  centerMenuNavigationFullWidthTab: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
});

export type IGeneralLayoutStylesParams = {
  preset: StyleParamType.Number;
};

export const generalLayoutStylesParams = createStylesParams<IGeneralLayoutStylesParams>({
  preset: {
    type: StyleParamType.Number,
    getDefaultValue: () => Preset.Grid,
  },
});

export type ILayoutStylesParams = IGeneralLayoutStylesParams &
  IGridLayoutStylesParams &
  IColumnsLayoutStylesParams &
  ICenterLayoutStylesParams &
  ISideBySideLayoutStylesParams;

export type LayoutStylesParamsValues = {
  [K in keyof ILayoutStylesParams]: ExtractStyleParamValue<(typeof LayoutStylesParams)[K]>;
};

export const LayoutStylesParams: StylesParamsType<ILayoutStylesParams> = {
  ...generalLayoutStylesParams,
  ...gridLayoutStylesParams,
  ...columnsLayoutStylesParams,
  ...centerLayoutStylesParams,
  ...sideBySideLayoutStylesParams,
};
