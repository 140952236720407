import { useCallback } from 'react';
import { useGetLayoutStylesParams } from 'root/hooks/useGetLayoutStylesParams';
import type { PopulatedMenu } from 'root/apiTypes';
import type { NavigateToFn, NavigateToParams } from 'root/clientTypes';
import { TabsVariant, type DropdownOptionProps, type TabItem, type TabsProps } from 'wix-ui-tpa/cssVars';
import { useGetDesignStylesParams } from 'root/hooks/useGetDesignStylesParams';
import { useGetNavigationAlignment } from 'root/hooks/preset/useGetNavigationAlignment';
import { useGetNavigationLayout } from 'root/hooks/preset/useGetNavigationLayout';
import { useGetNavigationFullWidthTab } from 'root/hooks/preset/useGetNavigationFullWidthTab';
import { buildMenuUrlFromCurrentUrl } from 'root/utils/navigation';
import type { ILocation } from '@wix/yoshi-flow-editor';
import { MenuListNavigation } from 'root/consts';

type UseNavigationLogicParams = {
  menus: PopulatedMenu[];
  setActiveItem: (index: number) => void;
  navigateTo: NavigateToFn;
  currentUrl: ILocation['url'];
};

export const useNavigationLogic = ({ menus, setActiveItem, navigateTo, currentUrl }: UseNavigationLogicParams) => {
  const { preset } = useGetLayoutStylesParams();
  const { navigationDropdownStyle } = useGetDesignStylesParams();

  const navigationAlignment = useGetNavigationAlignment(preset);
  const navigationLayout = useGetNavigationLayout(preset);
  const navigationFullWidthTab = useGetNavigationFullWidthTab(preset);

  const renderButHideTabsForSeo = navigationLayout === MenuListNavigation.Dropdown;

  const getMenuNavigationDetailsByIndex = useCallback(
    (index: number): NavigateToParams => {
      const menu = menus[index];
      const menuId = menu.id;
      const urlQueryParam = menu.urlQueryParam;
      return { currentUrl, menuId, urlQueryParam, isFirst: index === 0 };
    },
    [currentUrl, menus]
  );

  const tabsWidth = navigationFullWidthTab ? TabsVariant.fullWidth : TabsVariant.fit;
  const tabOptions = menus.map((menu, index): TabItem => {
    return {
      title: menu?.name,
      id: index.toString(),
      href: buildMenuUrlFromCurrentUrl(getMenuNavigationDetailsByIndex(index)),
    };
  });

  const dropdownOptions: DropdownOptionProps[] = tabOptions.map(
    (option): DropdownOptionProps => ({
      id: option.id,
      value: option.title,
      isSelectable: true,
    })
  );

  const selectMenu = (index: number) => {
    setActiveItem(index);
    navigateTo(getMenuNavigationDetailsByIndex(index));
  };

  const onClickTab: TabsProps['onTabClick'] = (index, e) => {
    e.preventDefault();
    selectMenu(index);
  };

  const onClickNavigationDropdownOption = (option: DropdownOptionProps) => {
    selectMenu(Number(option.id));
  };

  return {
    navigationLayout,
    tabsWidth,
    dropdownOptions,
    tabOptions,
    navigationAlignmentString: navigationAlignment,
    onClickNavigationDropdownOption,
    onClickTab,
    navigationDropdownStyle,
    renderButHideTabsForSeo,
  };
};
