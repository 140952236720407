import React from 'react';
import type { FC } from 'react';
import type { ILocation, WidgetProps } from '@wix/yoshi-flow-editor';
import { Menu } from 'root/components/Menus/Menu/Menu';
import type { PopulatedMenu } from 'root/apiTypes';
import { dataHooks } from 'root/dataHooks';
import type { NavigateToFn } from 'root/clientTypes';
import { useMenusLogic } from './Widget.logic';
import { AppWrapper } from 'root/components/Menus/AppWrapper/AppWrapper';
import { classes } from './Widget.st.css';
import { Navigation } from 'root/components/Menus/Navigation/Navigation';
import { MenuState } from 'root/components/Menus/MenuState/MenuState';
import { WowImage, ImageResizeOptions } from 'wix-ui-tpa/cssVars';
import { DEFAULT_MENUS_SIDE_IMAGE } from 'root/assets/images/MenusSideImage';

export type ControllerProps = {
  menus: PopulatedMenu[];
  navigateTo: NavigateToFn;
  error?: Error;
  url: ILocation['url'];
  fitToContentHeight: true;
  initialActiveTab: number;
};

export const Widget: FC<WidgetProps<ControllerProps>> = ({ menus, navigateTo, error, url, initialActiveTab }) => {
  const { activeTab, displayableMenus, shouldShowTabs, setActiveTab, shouldShowMenusSideImage, menusSideImage } =
    useMenusLogic({
      menus,
      initialActiveTab,
    });

  if (error) {
    return (
      <AppWrapper>
        <MenuState content="component.menu.error-state" />
      </AppWrapper>
    );
  }

  return (
    <AppWrapper>
      {displayableMenus?.length > 0 ? (
        <div className={classes.root} data-hook={dataHooks.menus.container}>
          <div className={classes.menuContainer}>
            {shouldShowMenusSideImage && (
              <div className={classes.imageContainer}>
                <WowImage
                  shouldUseLQIP
                  className={classes.sideImage}
                  data-hook={dataHooks.menu.sideImage}
                  resize={ImageResizeOptions.cover}
                  sourceHeight={menusSideImage?.height}
                  sourceWidth={menusSideImage?.width}
                  src={menusSideImage.url ? menusSideImage.url : DEFAULT_MENUS_SIDE_IMAGE}
                />
              </div>
            )}
            <div className={classes.menuContent}>
              {shouldShowTabs && (
                <Navigation
                  activeItem={activeTab}
                  currentUrl={url}
                  menus={displayableMenus}
                  navigateTo={navigateTo}
                  setActiveItem={setActiveTab}
                />
              )}
              <Menu menu={displayableMenus[activeTab]} />
            </div>
          </div>
        </div>
      ) : (
        <MenuState content="component.menu.empty-state" />
      )}
    </AppWrapper>
  );
};
